export { default as DuoActiveCall } from './ActiveCall';
export { default as DuoAddMusic } from './AddMusic';
export { default as DuoAddUser } from './AddUser';
export { default as DuoAddressBook1 } from './AddressBook1';
export { default as DuoAddressBook2 } from './AddressBook2';
export { default as DuoAddressCard } from './AddressCard';
export { default as DuoAdjust } from './Adjust';
export { default as DuoAirBalloon } from './AirBalloon';
export { default as DuoAirConditioning } from './AirConditioning';
export { default as DuoAirDryer } from './AirDryer';
export { default as DuoAirplayVideo } from './AirplayVideo';
export { default as DuoAirplay } from './Airplay';
export { default as DuoAirpods } from './Airpods';
export { default as DuoAlarmClock } from './AlarmClock';
export { default as DuoAlignAuto } from './AlignAuto';
export { default as DuoAlignCenter } from './AlignCenter';
export { default as DuoAlignJustify } from './AlignJustify';
export { default as DuoAlignLeft } from './AlignLeft';
export { default as DuoAlignRight } from './AlignRight';
export { default as DuoAnchorCenterDown } from './AnchorCenterDown';
export { default as DuoAnchorCenterUp } from './AnchorCenterUp';
export { default as DuoAnchorCenter } from './AnchorCenter';
export { default as DuoAnchorLeftDown } from './AnchorLeftDown';
export { default as DuoAnchorLeftUp } from './AnchorLeftUp';
export { default as DuoAnchorLeft } from './AnchorLeft';
export { default as DuoAnchorRightDown } from './AnchorRightDown';
export { default as DuoAnchorRightUp } from './AnchorRightUp';
export { default as DuoAnchorRight } from './AnchorRight';
export { default as DuoAndroid } from './Android';
export { default as DuoAngleDoubleDown } from './AngleDoubleDown';
export { default as DuoAngleDoubleLeft } from './AngleDoubleLeft';
export { default as DuoAngleDoubleRight } from './AngleDoubleRight';
export { default as DuoAngleDoubleUp } from './AngleDoubleUp';
export { default as DuoAngleDown } from './AngleDown';
export { default as DuoAngleGrinder } from './AngleGrinder';
export { default as DuoAngleLeft } from './AngleLeft';
export { default as DuoAngleRight } from './AngleRight';
export { default as DuoAngleUp } from './AngleUp';
export { default as DuoAppleWatch } from './AppleWatch';
export { default as DuoArchive } from './Archive';
export { default as DuoArmchair } from './Armchair';
export { default as DuoArrowDown } from './ArrowDown';
export { default as DuoArrowFromBottom } from './ArrowFromBottom';
export { default as DuoArrowFromLeft } from './ArrowFromLeft';
export { default as DuoArrowFromRight } from './ArrowFromRight';
export { default as DuoArrowFromTop } from './ArrowFromTop';
export { default as DuoArrowLeft } from './ArrowLeft';
export { default as DuoArrowRight } from './ArrowRight';
export { default as DuoArrowToBottom } from './ArrowToBottom';
export { default as DuoArrowToLeft } from './ArrowToLeft';
export { default as DuoArrowToRight } from './ArrowToRight';
export { default as DuoArrowToUp } from './ArrowToUp';
export { default as DuoArrowUp } from './ArrowUp';
export { default as DuoArrowsH } from './ArrowsH';
export { default as DuoArrowsV } from './ArrowsV';
export { default as DuoArrows } from './Arrows';
export { default as DuoArticle } from './Article';
export { default as DuoAtm } from './Atm';
export { default as DuoAttachment1 } from './Attachment1';
export { default as DuoAttachment2 } from './Attachment2';
export { default as DuoAxe } from './Axe';
export { default as DuoBack } from './Back';
export { default as DuoBackspace } from './Backspace';
export { default as DuoBackward } from './Backward';
export { default as DuoBag1 } from './Bag1';
export { default as DuoBag2 } from './Bag2';
export { default as DuoBagChair } from './BagChair';
export { default as DuoBakingGlove } from './BakingGlove';
export { default as DuoBarcodeRead } from './BarcodeRead';
export { default as DuoBarcodeScan } from './BarcodeScan';
export { default as DuoBarcode } from './Barcode';
export { default as DuoBath } from './Bath';
export { default as DuoBatteryCharging } from './BatteryCharging';
export { default as DuoBatteryEmpty } from './BatteryEmpty';
export { default as DuoBatteryFull } from './BatteryFull';
export { default as DuoBatteryHalf } from './BatteryHalf';
export { default as DuoBed } from './Bed';
export { default as DuoBeer } from './Beer';
export { default as DuoBezierCurve } from './BezierCurve';
export { default as DuoBinocular } from './Binocular';
export { default as DuoBitcoin } from './Bitcoin';
export { default as DuoBlender } from './Blender';
export { default as DuoBluetooth } from './Bluetooth';
export { default as DuoBold } from './Bold';
export { default as DuoBookOpen } from './BookOpen';
export { default as DuoBook } from './Book';
export { default as DuoBookmark } from './Bookmark';
export { default as DuoBorder } from './Border';
export { default as DuoBottle1 } from './Bottle1';
export { default as DuoBottle2 } from './Bottle2';
export { default as DuoBowl } from './Bowl';
export { default as DuoBox1 } from './Box1';
export { default as DuoBox2 } from './Box2';
export { default as DuoBox3 } from './Box3';
export { default as DuoBox } from './Box';
export { default as DuoBrassiere } from './Brassiere';
export { default as DuoBread } from './Bread';
export { default as DuoBriefcase } from './Briefcase';
export { default as DuoBroom } from './Broom';
export { default as DuoBrush1 } from './Brush1';
export { default as DuoBrush } from './Brush';
export { default as DuoBucket1 } from './Bucket1';
export { default as DuoBucket } from './Bucket';
export { default as DuoBuilding } from './Building';
export { default as DuoBulb1 } from './Bulb1';
export { default as DuoBulb2 } from './Bulb2';
export { default as DuoBulletList } from './BulletList';
export { default as DuoBurger } from './Burger';
export { default as DuoCake } from './Cake';
export { default as DuoCalculator } from './Calculator';
export { default as DuoCall1 } from './Call1';
export { default as DuoCall } from './Call';
export { default as DuoCamera } from './Camera';
export { default as DuoCap1 } from './Cap1';
export { default as DuoCap2 } from './Cap2';
export { default as DuoCap3 } from './Cap3';
export { default as DuoCap } from './Cap';
export { default as DuoCardboardVr } from './CardboardVr';
export { default as DuoCarrot } from './Carrot';
export { default as DuoCart1 } from './Cart1';
export { default as DuoCart2 } from './Cart2';
export { default as DuoCart3 } from './Cart3';
export { default as DuoCassette } from './Cassette';
export { default as DuoCd } from './Cd';
export { default as DuoCelsius } from './Celsius';
export { default as DuoChair1 } from './Chair1';
export { default as DuoChair2 } from './Chair2';
export { default as DuoChartBar1 } from './ChartBar1';
export { default as DuoChartBar2 } from './ChartBar2';
export { default as DuoChartBar3 } from './ChartBar3';
export { default as DuoChartLine1 } from './ChartLine1';
export { default as DuoChartLine2 } from './ChartLine2';
export { default as DuoChartPie } from './ChartPie';
export { default as DuoChat1 } from './Chat1';
export { default as DuoChat2 } from './Chat2';
export { default as DuoChat4 } from './Chat4';
export { default as DuoChat5 } from './Chat5';
export { default as DuoChat6 } from './Chat6';
export { default as DuoChatCheck } from './ChatCheck';
export { default as DuoChatError } from './ChatError';
export { default as DuoChatLocked } from './ChatLocked';
export { default as DuoChatSmile } from './ChatSmile';
export { default as DuoCheck } from './Check';
export { default as DuoCheese } from './Cheese';
export { default as DuoChef } from './Chef';
export { default as DuoChicken } from './Chicken';
export { default as DuoCircle } from './Circle';
export { default as DuoClip } from './Clip';
export { default as DuoClipboardCheck } from './ClipboardCheck';
export { default as DuoClipboardList } from './ClipboardList';
export { default as DuoClipboard } from './Clipboard';
export { default as DuoClock } from './Clock';
export { default as DuoClose } from './Close';
export { default as DuoCloud1 } from './Cloud1';
export { default as DuoCloud2 } from './Cloud2';
export { default as DuoCloudDownload } from './CloudDownload';
export { default as DuoCloudFog } from './CloudFog';
export { default as DuoCloudSun } from './CloudSun';
export { default as DuoCloudUpload } from './CloudUpload';
export { default as DuoCloudWind } from './CloudWind';
export { default as DuoCloudyNight } from './CloudyNight';
export { default as DuoCloudy } from './Cloudy';
export { default as DuoCmd } from './Cmd';
export { default as DuoCode1 } from './Code1';
export { default as DuoCode } from './Code';
export { default as DuoCoffee1 } from './Coffee1';
export { default as DuoCoffee2 } from './Coffee2';
export { default as DuoColorProfile } from './ColorProfile';
export { default as DuoColor } from './Color';
export { default as DuoCommit } from './Commit';
export { default as DuoCommode1 } from './Commode1';
export { default as DuoCommode2 } from './Commode2';
export { default as DuoCompass1 } from './Compass1';
export { default as DuoCompass } from './Compass';
export { default as DuoCompilation } from './Compilation';
export { default as DuoCompiledFile } from './CompiledFile';
export { default as DuoCompiling } from './Compiling';
export { default as DuoComponent } from './Component';
export { default as DuoContact1 } from './Contact1';
export { default as DuoControl } from './Control';
export { default as DuoCookie } from './Cookie';
export { default as DuoCookingBook } from './CookingBook';
export { default as DuoCookingPot } from './CookingPot';
export { default as DuoCouch } from './Couch';
export { default as DuoCpu1 } from './Cpu1';
export { default as DuoCpu2 } from './Cpu2';
export { default as DuoCreditCard } from './CreditCard';
export { default as DuoCrop } from './Crop';
export { default as DuoCrown } from './Crown';
export { default as DuoCursor } from './Cursor';
export { default as DuoCurtains } from './Curtains';
export { default as DuoCuttingBoard } from './CuttingBoard';
export { default as DuoDayRain } from './DayRain';
export { default as DuoDeer } from './Deer';
export { default as DuoDeleteUser } from './DeleteUser';
export { default as DuoDeletedFile } from './DeletedFile';
export { default as DuoDeletedFolder } from './DeletedFolder';
export { default as DuoDiagnostics } from './Diagnostics';
export { default as DuoDialNumbers } from './DialNumbers';
export { default as DuoDifference } from './Difference';
export { default as DuoDinner1 } from './Dinner1';
export { default as DuoDinner } from './Dinner';
export { default as DuoDirection1 } from './Direction1';
export { default as DuoDirection2 } from './Direction2';
export { default as DuoDish } from './Dish';
export { default as DuoDishes } from './Dishes';
export { default as DuoDislike } from './Dislike';
export { default as DuoDisplay1 } from './Display1';
export { default as DuoDisplay2 } from './Display2';
export { default as DuoDisplay3 } from './Display3';
export { default as DuoDollar } from './Dollar';
export { default as DuoDoneCircle } from './DoneCircle';
export { default as DuoDoorOpen } from './DoorOpen';
export { default as DuoDoubleCheck } from './DoubleCheck';
export { default as DuoDown2 } from './Down2';
export { default as DuoDownLeft } from './DownLeft';
export { default as DuoDownRight } from './DownRight';
export { default as DuoDownload } from './Download';
export { default as DuoDownloadedFile } from './DownloadedFile';
export { default as DuoDownloadsFolder } from './DownloadsFolder';
export { default as DuoDress } from './Dress';
export { default as DuoDuplicate } from './Duplicate';
export { default as DuoDvd } from './Dvd';
export { default as DuoEarth } from './Earth';
export { default as DuoEditText } from './EditText';
export { default as DuoEdit1 } from './Edit1';
export { default as DuoEdit } from './Edit';
export { default as DuoEject } from './Eject';
export { default as DuoEqualizer } from './Equalizer';
export { default as DuoEraser } from './Eraser';
export { default as DuoErrorCircle } from './ErrorCircle';
export { default as DuoEuro } from './Euro';
export { default as DuoExchange } from './Exchange';
export { default as DuoExpandArrows } from './ExpandArrows';
export { default as DuoExport } from './Export';
export { default as DuoFahrenheit } from './Fahrenheit';
export { default as DuoFan } from './Fan';
export { default as DuoFileCloud } from './FileCloud';
export { default as DuoFileDone } from './FileDone';
export { default as DuoFileMinus } from './FileMinus';
export { default as DuoFilePlus } from './FilePlus';
export { default as DuoFile } from './File';
export { default as DuoFilter } from './Filter';
export { default as DuoFire } from './Fire';
export { default as DuoFireplace } from './Fireplace';
export { default as DuoFish } from './Fish';
export { default as DuoFlag } from './Flag';
export { default as DuoFlashlight } from './Flashlight';
export { default as DuoFlatten } from './Flatten';
export { default as DuoFlipHorizontal } from './FlipHorizontal';
export { default as DuoFlipVertical } from './FlipVertical';
export { default as DuoFlower1 } from './Flower1';
export { default as DuoFlower2 } from './Flower2';
export { default as DuoFlower3 } from './Flower3';
export { default as DuoFog } from './Fog';
export { default as DuoFolderCheck } from './FolderCheck';
export { default as DuoFolderCloud } from './FolderCloud';
export { default as DuoFolderError } from './FolderError';
export { default as DuoFolderHeart } from './FolderHeart';
export { default as DuoFolderMinus } from './FolderMinus';
export { default as DuoFolderPlus } from './FolderPlus';
export { default as DuoFolderSolid } from './FolderSolid';
export { default as DuoFolderStar } from './FolderStar';
export { default as DuoFolderThunder } from './FolderThunder';
export { default as DuoFolder1 } from './Folder1';
export { default as DuoFolder } from './Folder';
export { default as DuoFont } from './Font';
export { default as DuoForkSpoonKnife } from './ForkSpoonKnife';
export { default as DuoForkSpoon } from './ForkSpoon';
export { default as DuoFork } from './Fork';
export { default as DuoForward1 } from './Forward1';
export { default as DuoForward } from './Forward';
export { default as DuoFrenchBread } from './FrenchBread';
export { default as DuoFridge } from './Fridge';
export { default as DuoFryingPan } from './FryingPan';
export { default as DuoGameboy } from './Gameboy';
export { default as DuoGamepad1 } from './Gamepad1';
export { default as DuoGamepad2 } from './Gamepad2';
export { default as DuoGasStove } from './GasStove';
export { default as DuoGenerator } from './Generator';
export { default as DuoGift } from './Gift';
export { default as DuoGit1 } from './Git1';
export { default as DuoGit2 } from './Git2';
export { default as DuoGit3 } from './Git3';
export { default as DuoGit4 } from './Git4';
export { default as DuoGithub } from './Github';
export { default as DuoGlassMartini } from './GlassMartini';
export { default as DuoGlobe } from './Globe';
export { default as DuoGrater } from './Grater';
export { default as DuoGroupChat } from './GroupChat';
export { default as DuoGroupFolders } from './GroupFolders';
export { default as DuoGroup } from './Group';
export { default as DuoH1 } from './H1';
export { default as DuoH2 } from './H2';
export { default as DuoHalfHeart } from './HalfHeart';
export { default as DuoHalfStar } from './HalfStar';
export { default as DuoHanger } from './Hanger';
export { default as DuoHardDrive } from './HardDrive';
export { default as DuoHat } from './Hat';
export { default as DuoHeadphones } from './Headphones';
export { default as DuoHeart } from './Heart';
export { default as DuoHidden } from './Hidden';
export { default as DuoHighVoltage } from './HighVoltage';
export { default as DuoHomeHeart } from './HomeHeart';
export { default as DuoHome } from './Home';
export { default as DuoHomepod } from './Homepod';
export { default as DuoHorizontal } from './Horizontal';
export { default as DuoHummer2 } from './Hummer2';
export { default as DuoHummer } from './Hummer';
export { default as DuoIceCream1 } from './IceCream1';
export { default as DuoIceCream2 } from './IceCream2';
export { default as DuoImac } from './Imac';
export { default as DuoImage } from './Image';
export { default as DuoImport } from './Import';
export { default as DuoIncomingBox } from './IncomingBox';
export { default as DuoIncomingCall } from './IncomingCall';
export { default as DuoIncomingMail } from './IncomingMail';
export { default as DuoInfoCircle } from './InfoCircle';
export { default as DuoInterSelect } from './InterSelect';
export { default as DuoIphoneBack } from './IphoneBack';
export { default as DuoIphoneXBack } from './IphoneXBack';
export { default as DuoIphoneX } from './IphoneX';
export { default as DuoIron } from './Iron';
export { default as DuoItalic } from './Italic';
export { default as DuoJoin1 } from './Join1';
export { default as DuoJoin2 } from './Join2';
export { default as DuoJoin3 } from './Join3';
export { default as DuoKettle } from './Kettle';
export { default as DuoKey } from './Key';
export { default as DuoKeyboard } from './Keyboard';
export { default as DuoKitchenScale } from './KitchenScale';
export { default as DuoKnife1 } from './Knife1';
export { default as DuoKnife2 } from './Knife2';
export { default as DuoKnifeFork1 } from './KnifeFork1';
export { default as DuoKnifeFork2 } from './KnifeFork2';
export { default as DuoLadder } from './Ladder';
export { default as DuoLadle } from './Ladle';
export { default as DuoLamp1 } from './Lamp1';
export { default as DuoLamp2 } from './Lamp2';
export { default as DuoLaptopMacbook } from './LaptopMacbook';
export { default as DuoLaptop } from './Laptop';
export { default as DuoLayers } from './Layers';
export { default as DuoLayout3D } from './Layout3D';
export { default as DuoLayout4Blocks } from './Layout4Blocks';
export { default as DuoLayoutArrange } from './LayoutArrange';
export { default as DuoLayoutGrid } from './LayoutGrid';
export { default as DuoLayoutHorizontal } from './LayoutHorizontal';
export { default as DuoLayoutLeftPanel1 } from './LayoutLeftPanel1';
export { default as DuoLayoutLeftPanel2 } from './LayoutLeftPanel2';
export { default as DuoLayoutRightPanel1 } from './LayoutRightPanel1';
export { default as DuoLayoutRightPanel2 } from './LayoutRightPanel2';
export { default as DuoLayoutTopPanel1 } from './LayoutTopPanel1';
export { default as DuoLayoutTopPanel2 } from './LayoutTopPanel2';
export { default as DuoLayoutTopPanel3 } from './LayoutTopPanel3';
export { default as DuoLayoutTopPanel4 } from './LayoutTopPanel4';
export { default as DuoLayoutTopPanel5 } from './LayoutTopPanel5';
export { default as DuoLayoutTopPanel6 } from './LayoutTopPanel6';
export { default as DuoLayoutVertical } from './LayoutVertical';
export { default as DuoLeft2 } from './Left2';
export { default as DuoLeft3 } from './Left3';
export { default as DuoLeftCircle } from './LeftCircle';
export { default as DuoLibrary } from './Library';
export { default as DuoLike } from './Like';
export { default as DuoLine } from './Line';
export { default as DuoLoader } from './Loader';
export { default as DuoLoading } from './Loading';
export { default as DuoLocationArrow } from './LocationArrow';
export { default as DuoLockCircle } from './LockCircle';
export { default as DuoLockOverturning } from './LockOverturning';
export { default as DuoLock } from './Lock';
export { default as DuoLockedFolder } from './LockedFolder';
export { default as DuoLte1 } from './Lte1';
export { default as DuoLte2 } from './Lte2';
export { default as DuoMagic } from './Magic';
export { default as DuoMailAt } from './MailAt';
export { default as DuoMailAttachment } from './MailAttachment';
export { default as DuoMailError } from './MailError';
export { default as DuoMailHeart } from './MailHeart';
export { default as DuoMailLocked } from './MailLocked';
export { default as DuoMailNotification } from './MailNotification';
export { default as DuoMailOpened } from './MailOpened';
export { default as DuoMailUnlocked } from './MailUnlocked';
export { default as DuoMail } from './Mail';
export { default as DuoMailbox2 } from './Mailbox2';
export { default as DuoMailbox } from './Mailbox';
export { default as DuoMarker1 } from './Marker1';
export { default as DuoMarker2 } from './Marker2';
export { default as DuoMask } from './Mask';
export { default as DuoMc } from './Mc';
export { default as DuoMediaFolder } from './MediaFolder';
export { default as DuoMediaLibrary1 } from './MediaLibrary1';
export { default as DuoMediaLibrary2 } from './MediaLibrary2';
export { default as DuoMediaLibrary3 } from './MediaLibrary3';
export { default as DuoMedia } from './Media';
export { default as DuoMenu } from './Menu';
export { default as DuoMic } from './Mic';
export { default as DuoMidi } from './Midi';
export { default as DuoMinus1 } from './Minus1';
export { default as DuoMinus } from './Minus';
export { default as DuoMirror } from './Mirror';
export { default as DuoMisoSoup } from './MisoSoup';
export { default as DuoMissedCall } from './MissedCall';
export { default as DuoMixer } from './Mixer';
export { default as DuoMoney } from './Money';
export { default as DuoMoon } from './Moon';
export { default as DuoMouse } from './Mouse';
export { default as DuoMovieLane1 } from './MovieLane1';
export { default as DuoMovieLane2 } from './MovieLane2';
export { default as DuoMusicCloud } from './MusicCloud';
export { default as DuoMusicNote } from './MusicNote';
export { default as DuoMusic1 } from './Music1';
export { default as DuoMusic } from './Music';
export { default as DuoMute } from './Mute';
export { default as DuoNext } from './Next';
export { default as DuoNightFog } from './NightFog';
export { default as DuoNightRain } from './NightRain';
export { default as DuoNotification2 } from './Notification2';
export { default as DuoNotifications1 } from './Notifications1';
export { default as DuoOption } from './Option';
export { default as DuoOrange } from './Orange';
export { default as DuoOther1 } from './Other1';
export { default as DuoOther2 } from './Other2';
export { default as DuoOutgoingBox } from './OutgoingBox';
export { default as DuoOutgoingCall } from './OutgoingCall';
export { default as DuoOutgoingMail } from './OutgoingMail';
export { default as DuoOutlet } from './Outlet';
export { default as DuoPanties } from './Panties';
export { default as DuoPantone } from './Pantone';
export { default as DuoParagraph } from './Paragraph';
export { default as DuoPatch } from './Patch';
export { default as DuoPause } from './Pause';
export { default as DuoPenRuler } from './PenRuler';
export { default as DuoPenToolVector } from './PenToolVector';
export { default as DuoPencil } from './Pencil';
export { default as DuoPhone } from './Phone';
export { default as DuoPicker } from './Picker';
export { default as DuoPicture } from './Picture';
export { default as DuoPictures1 } from './Pictures1';
export { default as DuoPictures2 } from './Pictures2';
export { default as DuoPixels } from './Pixels';
export { default as DuoPizza } from './Pizza';
export { default as DuoPlay } from './Play';
export { default as DuoPlaylist1 } from './Playlist1';
export { default as DuoPlaylist2 } from './Playlist2';
export { default as DuoPlus1 } from './Plus1';
export { default as DuoPlus } from './Plus';
export { default as DuoPolygon } from './Polygon';
export { default as DuoPosition1 } from './Position1';
export { default as DuoPosition } from './Position';
export { default as DuoPound } from './Pound';
export { default as DuoPrice1 } from './Price1';
export { default as DuoPrice2 } from './Price2';
export { default as DuoPrinter } from './Printer';
export { default as DuoProtectedFile } from './ProtectedFile';
export { default as DuoPuzzle } from './Puzzle';
export { default as DuoQuestionCircle } from './QuestionCircle';
export { default as DuoQuote1 } from './Quote1';
export { default as DuoQuote2 } from './Quote2';
export { default as DuoRadio } from './Radio';
export { default as DuoRain1 } from './Rain1';
export { default as DuoRain2 } from './Rain2';
export { default as DuoRain5 } from './Rain5';
export { default as DuoRainbow } from './Rainbow';
export { default as DuoRangeHood } from './RangeHood';
export { default as DuoReadedMail } from './ReadedMail';
export { default as DuoRec } from './Rec';
export { default as DuoRectangle } from './Rectangle';
export { default as DuoRedo } from './Redo';
export { default as DuoRepeatOne } from './RepeatOne';
export { default as DuoRepeat } from './Repeat';
export { default as DuoReplyAll } from './ReplyAll';
export { default as DuoReply } from './Reply';
export { default as DuoRight2 } from './Right2';
export { default as DuoRight3 } from './Right3';
export { default as DuoRightCircle } from './RightCircle';
export { default as DuoRight } from './Right';
export { default as DuoRoadCone } from './RoadCone';
export { default as DuoRoller } from './Roller';
export { default as DuoRollingPin } from './RollingPin';
export { default as DuoRouble } from './Rouble';
export { default as DuoRoulette } from './Roulette';
export { default as DuoRoute } from './Route';
export { default as DuoRouter1 } from './Router1';
export { default as DuoRouter2 } from './Router2';
export { default as DuoRss } from './Rss';
export { default as DuoRuler } from './Ruler';
export { default as DuoSad } from './Sad';
export { default as DuoSafeChat } from './SafeChat';
export { default as DuoSafe } from './Safe';
export { default as DuoSale1 } from './Sale1';
export { default as DuoSale2 } from './Sale2';
export { default as DuoSaturation } from './Saturation';
export { default as DuoSaucepan } from './Saucepan';
export { default as DuoSave } from './Save';
export { default as DuoScale } from './Scale';
export { default as DuoScissors } from './Scissors';
export { default as DuoScrewdriver } from './Screwdriver';
export { default as DuoSdCard } from './SdCard';
export { default as DuoSearch } from './Search';
export { default as DuoSelect } from './Select';
export { default as DuoSelectedFile } from './SelectedFile';
export { default as DuoSend } from './Send';
export { default as DuoSendingMail } from './SendingMail';
export { default as DuoSending } from './Sending';
export { default as DuoServer } from './Server';
export { default as DuoSettings1 } from './Settings1';
export { default as DuoSettings2 } from './Settings2';
export { default as DuoSettings3 } from './Settings3';
export { default as DuoSettings4 } from './Settings4';
export { default as DuoSettings } from './Settings';
export { default as DuoShare1 } from './Share1';
export { default as DuoShare } from './Share';
export { default as DuoShieldCheck } from './ShieldCheck';
export { default as DuoShieldDisabled } from './ShieldDisabled';
export { default as DuoShieldProtected } from './ShieldProtected';
export { default as DuoShieldThunder } from './ShieldThunder';
export { default as DuoShieldUser } from './ShieldUser';
export { default as DuoShift } from './Shift';
export { default as DuoShirt } from './Shirt';
export { default as DuoShoes } from './Shoes';
export { default as DuoShorts } from './Shorts';
export { default as DuoShovel1 } from './Shovel1';
export { default as DuoShovel } from './Shovel';
export { default as DuoShuffle } from './Shuffle';
export { default as DuoShutdown } from './Shutdown';
export { default as DuoSieve } from './Sieve';
export { default as DuoSignIn } from './SignIn';
export { default as DuoSignOut } from './SignOut';
export { default as DuoSize } from './Size';
export { default as DuoSketch } from './Sketch';
export { default as DuoSmile } from './Smile';
export { default as DuoSneakers } from './Sneakers';
export { default as DuoSnoozedMail } from './SnoozedMail';
export { default as DuoSnow1 } from './Snow1';
export { default as DuoSnow2 } from './Snow2';
export { default as DuoSnow3 } from './Snow3';
export { default as DuoSnow } from './Snow';
export { default as DuoSocketEu } from './SocketEu';
export { default as DuoSocketUs } from './SocketUs';
export { default as DuoSocks } from './Socks';
export { default as DuoSort1 } from './Sort1';
export { default as DuoSort2 } from './Sort2';
export { default as DuoSort3 } from './Sort3';
export { default as DuoSpam } from './Spam';
export { default as DuoSpatula } from './Spatula';
export { default as DuoSpeaker } from './Speaker';
export { default as DuoSpoon } from './Spoon';
export { default as DuoSpy } from './Spy';
export { default as DuoStairs } from './Stairs';
export { default as DuoStamp } from './Stamp';
export { default as DuoStar } from './Star';
export { default as DuoStop } from './Stop';
export { default as DuoStorm } from './Storm';
export { default as DuoStrikethrough } from './Strikethrough';
export { default as DuoSubtract } from './Subtract';
export { default as DuoSunFog } from './SunFog';
export { default as DuoSunGlasses } from './SunGlasses';
export { default as DuoSun } from './Sun';
export { default as DuoSunset1 } from './Sunset1';
export { default as DuoSunset2 } from './Sunset2';
export { default as DuoSushi } from './Sushi';
export { default as DuoSwissKnife } from './SwissKnife';
export { default as DuoTShirt } from './TShirt';
export { default as DuoTablet } from './Tablet';
export { default as DuoTarget } from './Target';
export { default as DuoTemperatureEmpty } from './TemperatureEmpty';
export { default as DuoTemperatureFull } from './TemperatureFull';
export { default as DuoTemperatureHalf } from './TemperatureHalf';
export { default as DuoTerminal } from './Terminal';
export { default as DuoTextHeight } from './TextHeight';
export { default as DuoTextWidth } from './TextWidth';
export { default as DuoText } from './Text';
export { default as DuoThumbtack } from './Thumbtack';
export { default as DuoThunderCircle } from './ThunderCircle';
export { default as DuoThunderMove } from './ThunderMove';
export { default as DuoThunderNight } from './ThunderNight';
export { default as DuoThunder1 } from './Thunder1';
export { default as DuoThunder } from './Thunder';
export { default as DuoTicket } from './Ticket';
export { default as DuoTie } from './Tie';
export { default as DuoTimeSchedule } from './TimeSchedule';
export { default as DuoTimer } from './Timer';
export { default as DuoToilet } from './Toilet';
export { default as DuoTools } from './Tools';
export { default as DuoTowel } from './Towel';
export { default as DuoTrash1 } from './Trash1';
export { default as DuoTrash } from './Trash';
export { default as DuoTriangle } from './Triangle';
export { default as DuoTv1 } from './Tv1';
export { default as DuoTv2 } from './Tv2';
export { default as DuoTwoBottles } from './TwoBottles';
export { default as DuoUmbrella } from './Umbrella';
export { default as DuoUnderline } from './Underline';
export { default as DuoUndo } from './Undo';
export { default as DuoUnion } from './Union';
export { default as DuoUnlock } from './Unlock';
export { default as DuoUp2 } from './Up2';
export { default as DuoUpBoard } from './UpBoard';
export { default as DuoUpDown } from './UpDown';
export { default as DuoUpLeft } from './UpLeft';
export { default as DuoUpRight } from './UpRight';
export { default as DuoUpdate } from './Update';
export { default as DuoUploadFolder } from './UploadFolder';
export { default as DuoUpload } from './Upload';
export { default as DuoUploadedFile } from './UploadedFile';
export { default as DuoUrgentMail } from './UrgentMail';
export { default as DuoUsbStorage } from './UsbStorage';
export { default as DuoUsb } from './Usb';
export { default as DuoUserFolder } from './UserFolder';
export { default as DuoUser } from './User';
export { default as DuoVertical } from './Vertical';
export { default as DuoVideoCamera } from './VideoCamera';
export { default as DuoVinyl } from './Vinyl';
export { default as DuoVisible } from './Visible';
export { default as DuoVolumeDown } from './VolumeDown';
export { default as DuoVolumeFull } from './VolumeFull';
export { default as DuoVolumeHalf } from './VolumeHalf';
export { default as DuoVolumeUp } from './VolumeUp';
export { default as DuoWaiting } from './Waiting';
export { default as DuoWallet2 } from './Wallet2';
export { default as DuoWallet3 } from './Wallet3';
export { default as DuoWallet } from './Wallet';
export { default as DuoWarning1Circle } from './Warning1Circle';
export { default as DuoWarning2 } from './Warning2';
export { default as DuoWasher } from './Washer';
export { default as DuoWatch1 } from './Watch1';
export { default as DuoWatch2 } from './Watch2';
export { default as DuoWaterMixer } from './WaterMixer';
export { default as DuoWeight1 } from './Weight1';
export { default as DuoWeight2 } from './Weight2';
export { default as DuoWiFi } from './WiFi';
export { default as DuoWind } from './Wind';
export { default as DuoWine } from './Wine';
export { default as DuoWood1 } from './Wood1';
export { default as DuoWood2 } from './Wood2';
export { default as DuoWoodHorse } from './WoodHorse';
export { default as DuoWrite } from './Write';
export { default as DuoYoutube } from './Youtube';
export { default as DuoZoomMinus } from './ZoomMinus';
export { default as DuoZoomPlus } from './ZoomPlus';
